













































import { Vue, Component, Prop } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import Upload from '@/components/common/Upload.vue';
import { UserNotificationTypeResponse, CreateUserNotificationTypeFormPayload } from '@/models/user_notification_types';
import { AcceptedImageFileType } from '@/utils/constants';
import { UploadAttachmentWithThumbnail } from '@/models/generic';
import { ValidationObserver } from 'vee-validate';
import { uploadMediaFile } from '@/utils/attachments';
import snakeCase from 'lodash/snakeCase';

@Component({
  components: {
    Dropdown,
    Upload,
    ValidationObserver,
  },
})
export default class UserNotificationTypeForm extends Vue {
  @Prop({ default: false })
  isEdit!: boolean;

  @Prop({ default: '' })
  defaultValues!: UserNotificationTypeResponse['data']['objects'];

  formData: CreateUserNotificationTypeFormPayload = {
    type: '',
    thumbnailurl: '',
    remarks: '',
  };

  reupload = false;

  get imageFileTypes() {
    return AcceptedImageFileType;
  }

  mounted() {
    if (this.isEdit) {
      this.formData = {
        ...this.defaultValues,
      };
    }
  }

  async onThumnailIconUpload(payload: UploadAttachmentWithThumbnail) {
    if (!payload.originalFile) {
      return;
    }

    const fileUrl = await uploadMediaFile(payload.originalFile as File);
    this.formData.thumbnailurl = fileUrl;
  }

  onSubmit() {
    const { ...formData } = this.formData;

    if(!this.isEdit) {
      formData.type = snakeCase(formData.type).toUpperCase();
    }

    this.$emit('submit', {
      ...formData,
    });
  }
}
